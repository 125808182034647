import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as React from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import { Roles } from 'shared/constants'
import * as Modals from 'shared/modals'
import { SearchAccount } from "../../common/search-accounts/component";
import { SearchCommunities } from "../../common/search-communities/component";
import { Community } from "../types";

interface TransferUserModalState extends Modals.ModalComponentState {
  communities: any[],
  isLoading: boolean,
  isSuperUser?: boolean,
  selectedCommunities: Community[],
  selectedAccountId: number
}

interface TransferUserModalProps extends Modals.ModalComponentProps {
  onSave: (
    removeCommunityIds: string[],
    userId: string,
    role: string,
    selectedAccountId: number,
    selectedCommunities: Community[],
  ) => Promise<any>
  push: any,
}

export class TransferUserModalComponent extends Modals.GenericModalComponent<TransferUserModalProps, TransferUserModalState> {
  public static contextType = RasaContext
  public static key: string = 'transferUser'
  public static defaultProps = {
    closeButtonText: 'Cancel',
    saveText: 'Transfer User',
    secondAction: Modals.ModalActionType.BUTTON,
    title: 'Transfer User',
  }
  constructor(props: TransferUserModalProps) {
    super(props, TransferUserModal.key, 'Transfer User')
    this.state = {
      communities: [],
      isLoading: true,
      isSaving: false,
      selectedCommunities: [],
      isSuperUser: false,
      selectedAccountId: 0,
    }
  }

  public componentDidMount() {
    if (this.context && this.context.user && this.context.user.activeCommunity) {
      this.setState({
        isSuperUser: this.context.user.activeCommunity.role === Roles.super_admin
      }, () => {
        if (this.state.isSuperUser) {
          TransferUserModalComponent.defaultProps.secondAction = null
        }
      })
    }
  }

  protected renderChildren(data: any) {
    return (
      <div className="invite-user-modal-body">
        { this.state.isSaving
        ? <Loading size="64"/>
        : <div>
            {this.state.isSuperUser &&
              <div>
                <SearchAccount onAccountSelect = {this.handleAccountSelectCallback}/>
                { this.state.selectedAccountId > 0 &&
                  <SearchCommunities
                    selectedAccountId={this.state.selectedAccountId}
                    onCommunitySelect={this.handleCommunitySelectCallback}/>
                }
              </div>
            }
          </div>
        }
      </div>
    )
  }
  protected handleAccountSelectCallback = (accountData) => {
    this.setState({selectedAccountId: accountData.id})
  }

  protected handleCommunitySelectCallback = (selectedCommunities: Community[]) => {
    this.setState({selectedCommunities})
  }

  protected close = (data: any) => {
    this.handleClose()
  }

  protected xButtonClick(data: any) {
    this.handleClose()
  }

  protected doSave(data: any) {
    return this.props.onSave(
      data.removeCommunityIds,
      data.userId,
      data.role,
      this.state.selectedAccountId,
      this.state.selectedCommunities,
    ).then(() => {
      this.setState({
        isSaving: false,
        selectedCommunities: [],
      })
    });
  }

  private handleClose() {
    this.setState({
      selectedCommunities: [],
      selectedAccountId: 0
    })
    this.props.closeModal(TransferUserModal.key)
  }
}

export const TransferUserModal = connect(
  null,
  {
    push: Router.push,
  },
)(TransferUserModalComponent)

import React from 'react'

import { ToggleOff } from 'components/icons/toggleoff'
import { ToggleOn } from 'components/icons/toggleon'
import { Language } from '../constants';

interface LanguageItemProps extends React.HTMLAttributes<HTMLElement> {
  isActive: boolean,
  item: Language,
  onToggle: any,
}

export class LanguageItem extends React.Component<LanguageItemProps> {
  constructor(props: LanguageItemProps) {
    super(props)
  }
  public render() {
      return (
        <div className="language-item section">
          <div className="block">
              <div className="toggle-active"
                  onClick={() => this.props.onToggle(this.props.item.code, !this.props.isActive)}>
                <span className="small-description">{this.props.item.name}</span>
                <button className="toggle-button"
                        style={{background: 'none', border: 'none', outline: 'none'}}>
                  {this.props.isActive ? <ToggleOn/> : <ToggleOff/> }
                </button>
              </div>
            </div>
          </div>
      );
  }
}

import { Tooltip } from '@progress/kendo-react-tooltip'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import React from 'react'
import * as Flash from '../../../flash';
import * as Constants from '../../constants'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

declare const RASA_SELF_SERVICE_API: string

export class InformzConfigRender extends IntegrationConfigRenderBase {
  protected supportsFilter: boolean = false
  protected showContactsCount = false
  private systemAppKey: string = 'systemAppKey'
  private systemAppId: string = 'systemAppId'
  private systemAppUserEmail: string = 'systemAppUserEmail'
  private systemAppUserPassword: string = 'systemAppUserPassword'
  private selectedFilterName: string = 'selectedFilterName'

  protected initializeCustomProps() {
    if (this.systemApiAttribute && this.systemApiAttribute.encryption_key) {
      this.props.propertiesChanged({
        [this.systemAppKey]: this.systemApiAttribute.encryption_key,
      })
    }
    if (this.systemApiAttribute && this.systemApiAttribute.brand_id) {
      this.props.propertiesChanged({
        [this.systemAppId]: this.systemApiAttribute.brand_id,
      })
    }
    if (this.systemApiAttribute && this.systemApiAttribute.user_name) {
      this.props.propertiesChanged({
        [this.systemAppUserEmail]: this.systemApiAttribute.user_name,
      })
    }
    if (this.systemApiAttribute && this.systemApiAttribute.password) {
      this.props.propertiesChanged({
        [this.systemAppUserPassword]: this.systemApiAttribute.password,
      })
    }
    if (this.systemApiAttribute && this.systemApiAttribute.interests) {
      this.props.propertiesChanged({
        [this.selectedFilterName]: this.systemApiAttribute.interests,
      })
    }
  }

  protected isAuthenticateButtonEnabled(): boolean {
    return !this.state.isConnecting && !!this.props.data.selectedFilterName && !!this.props.data.systemAppKey
      && !!this.props.data.systemAppId && !!this.props.data.systemAppUserEmail && !!this.props.data.systemAppUserPassword
  }

  protected connect() {
    const url = this.buildPopUpUrl()
    this.setState({
      isConnecting: true,
    })
    AjaxWrapper.ajax(url, HttpMethod.GET, {})
    .then((result) => {
      if (!!result.success) {
        window.location.href = Constants.INTEGRATIONS_CONFIG_URL + '?id=' + result.payload.communitySystemId + '&operation=' + `${result.operation}${Constants.SUCCESS}`
      } else {
        this.setState({
          isConnecting: false,
        })
        const errMsg = result.payload ? result.payload.message : ''
        this.props.context.store.dispatch(Flash.showFlashError(errMsg))
      }
    }).catch((err) => {
      this.setState({
        isConnecting: false,
      })
      let message = ''
      try {
        message = err.response.message
      } catch (e) {
        message = err.message
      }
      this.props.context.store.dispatch(Flash.showFlashError(message))
    })
  }

  protected buildPopUpUrl(): string {
    return `${RASA_SELF_SERVICE_API}/integrations/${this.props.data.systemName.replace(' ', '').toLowerCase()}-api-callback?state=${this.encodedCommunityIdentifier}&encryption_key=${this.props.data.systemAppKey}&brand_id=${this.props.data.systemAppId}&user_name=${this.props.data.systemAppUserEmail}&password=${this.props.data.systemAppUserPassword}&interests=${this.props.data.selectedFilterName}`
  }

  protected renderCustomBox() {
    return <div className="api-key-wrapper">
      <div>
        {this.createTextBox('Brand Id', this.systemAppId)}
      </div>
      <div>
        {this.createTextBox('User Name', this.systemAppUserEmail)}
      </div>
      <div>
        {this.createTextBox('Password', this.systemAppUserPassword)}
      </div>
      <div>
        {this.createTextBox('Encryption Key', this.systemAppKey)}
      </div>
      <div>
        {this.createTextBox('Interests', this.selectedFilterName)}
      </div>
    </div>
  }

  private createTextBox(displayName: string, fieldName: string, helpText: string = '') {
    return <div>
      <Tooltip openDelay={100} position="top" anchorElement="pointer" className="rasa-grid-tooltip">
        <div className="field-label-credentials">
          <span title={helpText || `Input your ${displayName}`}>
            {displayName} (Required)
          </span>
        </div>
      </Tooltip>
      <div className="input-wrapper">
        {!this.props.data.is_active
        ? <input className={`field-textbox field-textbox-${fieldName} system-api-key`}
            value={(this.props.data[fieldName] || '')}
            onChange={(e) => this.props.propertiesChanged({[fieldName]: e.target.value})}
            placeholder={`Enter your ${displayName}`}
          />
        : <div className={`field-textbox field-textbox-${fieldName} system-api-key`}>
            {(this.props.data[fieldName] || '')}
          </div>
        }
      </div>
    </div>
  }
}

import React from 'react'

import { ToggleOff } from 'components/icons/toggleoff'
import { ToggleOn } from 'components/icons/toggleon'

import { EmailPreference } from '../constants'

interface EmailPreferenceItemProps extends React.HTMLAttributes<HTMLElement> {
  preference: EmailPreference,
  isActive: boolean,
  onToggle: any
}

export class EmailPreferenceItem extends React.Component<EmailPreferenceItemProps> {
  constructor(props: EmailPreferenceItemProps) {
    super(props)
  }
  public render() {
      return (
        <div className={this.props.className ? 'preference-box ' + this.props.className : 'preference-box'}>
          <div className="box-body">
            <div className="left">
              <div className="box-header">{this.props.preference.heading}</div>
              <div className="box-content">{this.props.preference.description}</div>
            </div>
            <div className="right">
              <div onClick={this.props.onToggle} className="toggle-active">
                <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
                  {this.props.isActive ? <ToggleOn/> : <ToggleOff/>}
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      );
  }
}

import {
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid'
import { ActiveCell } from 'components/content-pool/kendocells/activeCell'
import { DropdownComponent } from 'components/dropdown/component'
import { SearchIcon } from 'components/icons/searchicon'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { RasaDataGrid } from 'generic/rasaDataGrid'
import { validateEmail } from 'generic/utility'
import * as React from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import { Roles } from 'shared/constants'
import * as Modals from 'shared/modals'
import * as Constants from '../constants'

interface Community {
  id: number,
  identifier: string,
  name: string,
}
interface InviteUserModalState extends Modals.ModalComponentState {
  addedCommunities: string[],
  communities: any[],
  community_account_id: string,
  email: string,
  first_name: string,
  last_name: string,
  hasSingleNewsletterAccess: boolean,
  isLoading: boolean,
  isSuperUser?: boolean,
  removedCommunities: string[],
  search?: string,
  selectedCommunities: Community[],
  showCommunitiesList: boolean,
  userId: string,
  userRole: any,
}

interface InviteUserModalProps extends Modals.ModalComponentProps {
  onSave: (
    first_name: string,
    lastName: string,
    email: string,
    selectedCommunities: Community[],
    userRole: any,
  ) => Promise<any>
  onUpdate: (
    userId: string,
    selectedCommunities: string[],
    removedCommunities: string[],
    userRole: any,
  ) => Promise<any>,
  push: any,
}

export class InviteUserModalComponent extends Modals.GenericModalComponent<InviteUserModalProps, InviteUserModalState> {
  public static contextType = RasaContext
  public static key: string = 'inviteUser'
  public static defaultProps = {
    closeButtonText: 'Cancel',
    saveText: 'Send Invite',
    secondAction: Modals.ModalActionType.BUTTON,
    title: 'Invite User',
  }
  private inviteUsersSearchTypingTimer
  private inviteUsersSearchTypingTimerInterval = 500 // .5 seconds
  constructor(props: InviteUserModalProps) {
    super(props, InviteUserModalComponent.key, 'Invite User')
    this.state = {
      addedCommunities: [],
      email: '',
      first_name: '',
      last_name: '',
      communities: [],
      hasSingleNewsletterAccess: false,
      isLoading: true,
      isSaving: false,
      removedCommunities: [],
      selectedCommunities: [],
      showCommunitiesList: false,
      community_account_id: '',
      userId: '',
      userRole: Constants.AllowedRoles[0].key,
      isSuperUser: false,
      search: '',
    }
    this.transformData = this.transformData.bind(this)
    this.onToggle = this.onToggle.bind(this)
  }

  public componentWillReceiveProps(nextProps) {
    const modalData = nextProps.data[InviteUserModal.key].data
    if (this.context && this.context.user && this.context.user.activeCommunity) {
      this.setState({
        isSuperUser: this.context.user.activeCommunity.role === Roles.super_admin,
        community_account_id: this.context.user.activeCommunity.data.community_account_id,
      }, () => {
        if (this.state.isSuperUser) {
          InviteUserModalComponent.defaultProps.secondAction = null
        }
      })
    }
    if (modalData && modalData.userRole && modalData.userRole !== this.state.userRole) {
      this.setState({
        userRole: modalData.userRole,
      })
    }
  }

  protected renderChildren(data: any) {
    return (
      <div className="invite-user-modal-body">
        { this.state.isSaving
        ? <Loading size="64"/>
        : <div>
            {!data.isForEnablingUser && <div>
            {!data.userId &&
              <div>
                <h4>Enter email to invite</h4>
                <div className="mb-3">
                  <input
                    className="invite-email"
                    type="text"
                    value={this.state.email}
                    placeholder="Email..."
                    onChange={(e) => {
                      e.preventDefault()
                      this.setState({email: e.target.value})
                    }}
                  />
                </div>
                <div className="mb-3">
                  <input
                    className="invite-email"
                    type="text"
                    value={this.state.first_name}
                    placeholder="First Name"
                    onChange={(e) => {
                      e.preventDefault()
                      this.setState({first_name: e.target.value})
                    }}
                  />
                </div>
                <div className="mb-3">
                  <input
                    className="invite-email"
                    type="text"
                    value={this.state.last_name}
                    placeholder="Last Name"
                    onChange={(e) => {
                      e.preventDefault()
                      this.setState({last_name: e.target.value})
                    }}
                  />
                </div>
              </div>}
              <div className="roles-wrapper">
                <DropdownComponent selected={this.state.userRole}
                  data={Constants.AllowedRoles} onChange={(item) => {
                  this.setState({userRole: item.selected.key})
                }} />
              </div>
            </div>}
            { this.state.isLoading && this.state.showCommunitiesList &&
              <div>
                <div className="invite-user-search-accounts">
                  <h4 className="label">Select your Newsletter</h4>
                  <div className="search-bar-and-buttons-container">
                    <div className="search-bar">
                      <label>
                        <SearchIcon />
                        <input
                            type="text"
                            value={this.state.search}
                            placeholder="Search newsletter..."
                            onChange={(e) => {
                              const searchValue = e.target.value
                              clearTimeout(this.inviteUsersSearchTypingTimer)
                              this.setState({showCommunitiesList: false}, () => {
                                  this.setSearch(searchValue)
                                  this.inviteUsersSearchTypingTimer = setTimeout(this.onInviteUserSearchTypingDone,
                                    this.inviteUsersSearchTypingTimerInterval)
                            })
                            }}
                        />
                        </label>
                  </div>
                </div>
              </div>
            </div>}
            { ( this.state.isLoading || this.state.showCommunitiesList ) && this.renderGrid() }
          </div>
        }
      </div>
    )
  }
  protected renderGrid() {
    return <div className="invite-user-newsletter-grid">
    <RasaDataGrid
              // Rasa Properties for entity, datasetName etc
              entityName="communityArticle"
              datasetName="userNewsletters"
              datasetParams={[
                { param: 'filter', value: this.state.search },
                { param: 'accountId', value: this.state.community_account_id },
              ]}
              onDataReceived={this.dataReceived}
              gridTransform={this.transformData}
              editDefaultState={true}
              sortable={false}
              pageable={false}
              gridFilter={this.filterData}
              data={[]}>
            <Column cell={this.ActiveCell} width={170}/>
            <Column
              field="community_name"
              title={'Name'}
            />
          </RasaDataGrid>
    </div>
  }
  protected getSaveText(data: any): string {
    return data.isForEnablingUser ? 'Enable User' : data.userId ? 'Update User' : 'Send Invite'
  }
  protected getTitleText(data: any): string {
    return data.isForEnablingUser ? 'Enable User' : data.userId ? 'Update User' : 'Invite User'
  }
  protected close = (data: any) => {
    this.handleClose()
  }

  protected xButtonClick(data: any) {
    this.handleClose()
  }

  protected doSave(data: any) {
    const role = Constants.getRoleByDisplayName(this.state.userRole)
    if ( data.userId ) {
      return this.props.onUpdate(
        data.userId,
        this.state.addedCommunities,
        this.state.removedCommunities,
        role,
      ).then(() => {
        this.setState({
          addedCommunities: [],
          isSaving: false,
          userRole: Constants.AllowedRoles[0].key,
          removedCommunities: [],
          selectedCommunities: this.state.showCommunitiesList ? [] : this.state.selectedCommunities,
          search: '',
        })
      })
    } else {
      return this.props.onSave(
        this.state.first_name,
        this.state.last_name,
        this.state.email,
        this.state.selectedCommunities,
        role,
      ).then(() => {
        this.setState({
          isSaving: false,
          email: '',
          first_name: '',
          last_name: '',
          selectedCommunities: this.state.showCommunitiesList ? [] : this.state.selectedCommunities,
          userRole: Constants.AllowedRoles[0].key,
          search: '',
        })
      });
    }
  }

  protected saveDisabled(data: any) {
    return data.userId ?
      (
        (
          this.state.addedCommunities.length === 0 &&
          this.state.removedCommunities.length === 0 &&
          data.userRole === this.state.userRole
        ) || (
          this.state.addedCommunities.length === 0 &&
          this.state.removedCommunities.length === this.state.selectedCommunities.length &&
          data.userRole === this.state.userRole
        )
      ) : (
        !validateEmail(this.state.email) ||
        (
          this.state.selectedCommunities.length === 0 &&
          this.state.communities.length > 1
        )
      )
  }

  private filterData = (a: any): boolean => {
    return (a.community_account_id === this.state.community_account_id)
  }

  private handleClose() {
    this.setState({
      hasSingleNewsletterAccess: false,
      search: '',
      selectedCommunities: [],
      addedCommunities: [],
      removedCommunities: [],
      showCommunitiesList: true,
    })
    this.props.closeModal(InviteUserModal.key)
  }

  private ActiveCell = (props: GridCellProps) => {
    return <ActiveCell {...props} onChange={null} onToggle={this.onToggle} fieldName="is_selected" />
  }

  private dataReceived = (data) => {
    let communities = (data.data || []).filter((a) => this.filterData(a))
    communities = communities.map((x) => {
      return {
        ...x,
        community_name: x.display_name || x.community_name || x.community_identifier
      }
    })
    let selectedCommunities = []

    if (!this.state.search.length && communities.length === 1) {
      // if user has only one newsletter access then do not show the grid
      selectedCommunities.push({
        id: communities[0].identity_community_id,
        identifier: communities[0].id,
        name: communities[0].community_name,
      })
      this.setState({ showCommunitiesList: false, hasSingleNewsletterAccess: true })
    } else {
      this.setState({ showCommunitiesList: true, hasSingleNewsletterAccess: false })
    }

    if (this.props.data[InviteUserModal.key].data &&
        this.props.data[InviteUserModal.key].data.selectedCommunities) {

      const selectedCommunityIds = this.props.data[InviteUserModal.key].data.selectedCommunities.split(',')
      communities = data.data.map((x) => {
        return {
          ...x,
          is_selected: selectedCommunityIds.includes(x.identity_community_id.toString()),
        }
      })
      selectedCommunities = communities.filter((x) =>
        selectedCommunityIds.includes(x.identity_community_id.toString())).map((x) => {
          return {
            id: x.identity_community_id,
            identifier: x.id,
            name: x.community_name,
          }
      })
    }

    this.setState({
      communities,
      isLoading: false,
      selectedCommunities,
    })
  }

  private transformData = (a) => {
    const filteredCommunities = this.state.communities.filter(
      (x) => x.identity_community_id === a.identity_community_id)
    return filteredCommunities.length ? filteredCommunities[0] : a
  }

  private onToggle = (item) => {
    if (this.props.data[InviteUserModal.key].data.userId) {
      if (this.state.selectedCommunities.filter((x) => x.id === item.identity_community_id).length > 0) {
        if (this.state.removedCommunities.includes(item.identity_community_id)) {
          this.setState({
            removedCommunities: this.state.removedCommunities.filter((x) => x !== item.identity_community_id),
          })
        } else {
          this.setState({
            removedCommunities: [...this.state.removedCommunities, item.identity_community_id],
          })
        }
      } else {
        if (this.state.addedCommunities.includes(item.identity_community_id)) {
          this.setState({
            addedCommunities: this.state.addedCommunities.filter((x) => x !== item.identity_community_id),
          })
        } else {
          this.setState({
            addedCommunities: [...this.state.addedCommunities, item.identity_community_id],
          })
        }
      }
    } else {
      const existingItem = this.state.selectedCommunities.filter((x) => x.id === item.identity_community_id)[0]
      if (!existingItem) {
        this.setState({
          selectedCommunities: [...this.state.selectedCommunities, {
            id: item.identity_community_id,
            identifier: item.id,
            name: item.community_name,
          }],
        })
      } else {
        this.setState({
          selectedCommunities: this.state.selectedCommunities.filter((x) => x.id !== item.identity_community_id),
        })
      }
    }
    const updatedCommunities = this.state.communities.map((x) => {
      return {
        ...x,
        is_selected: x.identity_community_id === item.identity_community_id ?
          x.is_selected ? false : true : x.is_selected || false,
      }
    })
    this.setState({
      communities: updatedCommunities,
    })
  }

  private onInviteUserSearchTypingDone = () => {
    this.setState({showCommunitiesList: true})
  }

  private setSearch = (s: string) => {
    this.setState({
      search: s,
    })
  }
}

export const InviteUserModal = connect(
  null,
  {
    push: Router.push,
  },
)(InviteUserModalComponent)

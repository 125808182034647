import { CONTENT_ADD_ZOOM_PAGE } from 'components/content-pool/constants'
import React from 'react'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

declare const RASA_SELF_SERVICE_API: string

export class ZoomConfigRender extends IntegrationConfigRenderBase {
  protected supportsFilter: boolean = false

  protected initializeCustomState(): Promise<void> {
    if (this.props.params.operation) {
      if (!this.props.params.operation.includes('Failure')) {
        if (this.props.params.ref) {
          this.props.push(`${this.props.params.ref}?connected=true`)
        }
      }
    }
    return Promise.resolve()
  }

  protected renderDisconnect() {
    if (!!this.props.data.is_active) {
      return <div className="connect-disconnect-wrapper">
        <div className={'save-button clickable-item save-button-enabled disconnect'}
          onClick={(e) => this.disconnect()}>Disconnect
        </div>
      </div>
    }
  }

  protected buildPopUpUrl(): string {
    const systemName = this.props.data.systemName
    const scopes: string = this.props.data.systemMetadata.scopes.join(',')
    let state = `${this.encodedCommunityIdentifier}`
    state += btoa(`|${this.props.params.ref || CONTENT_ADD_ZOOM_PAGE}`)

    const popupUrl = `${this.systemInfo.keyUrl}${state}&scope=${scopes}&client_id=${this.props.clientIds[systemName]}&redirect_uri=${RASA_SELF_SERVICE_API}/integrations/${systemName.toLowerCase()}-oauth-callback`
    return popupUrl
  }
}

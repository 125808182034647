import { Tooltip } from '@progress/kendo-react-tooltip'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import React from 'react'
import * as Flash from '../../../flash';
import * as Constants from '../../constants'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

declare const RASA_SELF_SERVICE_API: string

export class StripeConfigRender extends IntegrationConfigRenderBase {
  protected showContactsCount = false
  private systemApiKeyName: string = 'systemApiKey'

  protected initializeCustomProps() {
    if (this.systemApiAttribute && this.systemApiAttribute.access_token) {
      this.props.propertiesChanged({
        [this.systemApiKeyName]: this.systemApiAttribute.access_token,
      })
    }
  }

  protected isAuthenticateButtonEnabled(): boolean {
    return !this.state.isConnecting && !!this.props.data.systemApiKey
  }

  protected connect() {
    const url = this.buildPopUpUrl()
    this.setState({
      isConnecting: true,
    })
    AjaxWrapper.ajax(url, HttpMethod.GET, {})
    .then((result) => {
      if (!!result.success) {
        window.location.href = Constants.INTEGRATIONS_CONFIG_URL + '?id=' + result.payload.communitySystemId + '&operation=' + `${result.operation}${Constants.SUCCESS}`
      } else {
        this.setState({
          isConnecting: false,
        })
        const errMsg = result.payload ? result.payload.message : ''
        this.props.context.store.dispatch(Flash.showFlashError(errMsg))
      }
    })
  }

  protected buildPopUpUrl(): string {
    return `${RASA_SELF_SERVICE_API}/integrations/${this.props.data.systemName.toLowerCase()}-api-callback?state=${this.encodedCommunityIdentifier}&api_key=${this.props.data.systemApiKey}`
  }

  protected renderCustomBox() {
    return <div className="api-key-wrapper">
      {this.createTextBox('Api Key', this.systemApiKeyName)}
    </div>
  }

  private createTextBox(displayName: string, fieldName: string) {
    return <div>
      <Tooltip openDelay={100} position="top" anchorElement="pointer" className="rasa-grid-tooltip">
        <div className="field-label-credentials">
          <span title="Input your api key">
            {displayName}
          </span>
        </div>
      </Tooltip>
      <div className="input-wrapper">
        {!this.props.data.is_active
        ? <input className={`field-textbox field-textbox-${fieldName} system-api-key`}
            value={(this.props.data[fieldName] || '')}
            onChange={(e) => this.props.propertiesChanged({[fieldName]: e.target.value})}
            placeholder="Enter your api key"
          />
        : <div className={`field-textbox field-textbox-${fieldName} system-api-key`}>
            {(this.props.data[fieldName] || '')}
          </div>
        }
      </div>
    </div>
  }
}

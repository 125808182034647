import { Tooltip } from '@progress/kendo-react-tooltip'
import * as Flash from 'components/flash'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import React from 'react'
import { Col } from 'reactstrap'
import * as Constants from '../../constants'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

declare const RASA_SELF_SERVICE_API: string

export class ImisConfigRender extends IntegrationConfigRenderBase {
  protected showContactsCount = false
  private apiEndpoint: string = 'api_endpoint'
  private queryName: string = 'query_name'
  private username: string = 'username'
  private password: string = 'password'

  protected initializeCustomProps() {
    if (this.systemApiAttribute && this.systemApiAttribute[this.apiEndpoint]) {
      this.props.propertiesChanged({
        [this.apiEndpoint]: this.systemApiAttribute[this.apiEndpoint],
      })
    }
    if (this.systemApiAttribute && this.systemApiAttribute[this.queryName]) {
      this.props.propertiesChanged({
        [this.queryName]: this.systemApiAttribute[this.queryName],
      })
    }
    if (this.systemApiAttribute && this.systemApiAttribute[this.username]) {
      this.props.propertiesChanged({
        [this.username]: this.systemApiAttribute[this.username],
      })
    }
    if (this.systemApiAttribute && this.systemApiAttribute[this.password]) {
      this.props.propertiesChanged({
        [this.password]: this.systemApiAttribute[this.password],
      })
    }
  }

  protected getContacts(communitySystemId: number, selectedFilterId: string, selectedFilterName: string) {
    // not supported
  }

  protected isAuthenticateButtonEnabled(): boolean {
    return !this.state.isConnecting && !!this.props.data.api_endpoint && !!this.props.data.username
      && !!this.props.data.password
  }

  protected connect() {
    const url = this.buildPopUpUrl()
    this.setState({
      isConnecting: true,
    })
    AjaxWrapper.ajax(url, HttpMethod.GET, {})
    .then((result) => {
      if (!!result.success) {
        window.location.href = Constants.INTEGRATIONS_CONFIG_URL + '?id=' + result.payload.communitySystemId + '&operation=' + `${result.operation}${Constants.SUCCESS}`
      } else {
        this.setState({
          isConnecting: false,
        })
        const errMsg = result.payload ? result.payload.message : ''
        this.props.context.store.dispatch(Flash.showFlashError(errMsg))
      }
    }).catch((err) => {
      this.setState({
        isConnecting: false,
      })
      let message = ''
      try {
        message = err.response.message
      } catch (e) {
        message = err.message
      }
      this.props.context.store.dispatch(Flash.showFlashError(message))
    })
  }

  protected buildPopUpUrl(): string {
    return `${RASA_SELF_SERVICE_API}/integrations/${this.props.data.systemName.replace(' ', '').toLowerCase()}-api-callback` +
      `?state=${this.encodedCommunityIdentifier}&api_endpoint=${this.props.data.api_endpoint}&query_name=${this.props.data.query_name}&username=${this.props.data.username}&password=${this.props.data.password}`
  }

  protected renderCustomBox() {
    return <div className="api-key-wrapper">
      <div>
        {this.createTextBox('Api Endpoint', this.apiEndpoint, true,
          'Input your api endpoint. Ex: https://myserver.com')}
      </div>
      <div>
        {this.createTextBox('IQA QueryName', this.queryName, true,
          'Input your QueryName. Ex: $/Common/Queries/Search/Contact')}
      </div>
      <div>
        {this.createTextBox('Username', this.username)}
      </div>
      <div>
        {this.createTextBox('Password', this.password)}
      </div>
    </div>
  }

  protected renderFinalizeHtml() {
    return <div>
      <Col className="confirm-connect">
        <p className="text">You are about to connect your contacts from QueryName "{this.props.data.query_name}".
          &nbsp;Is that what you meant to do?
        </p>
        <p className="confirm">
          <input className="checkbox" type="checkbox"
            checked={this.state.confirmed}
            onChange={() => this.setState({ confirmed: !this.state.confirmed })}
            id="confirm-checkbox" />
          <label htmlFor="confirm-checkbox">Yes</label>
        </p>
      </Col>
      <div className={this.state.confirmed ? 'save-button clickable-item save-button-enabled authenticate' :
        'save-button clickable-item save-button-disabled authenticate not-allowed'}
        onClick={() => this.finalizeSetup()}> Finalize connection
      </div>
    </div>
  }

  private createTextBox(displayName: string, fieldName: string, isRequired: boolean = true, helpText: string = '') {
    return <div>
      <Tooltip openDelay={100} position="top" anchorElement="pointer" className="rasa-grid-tooltip">
        <div className="field-label-credentials">
          <span title={helpText || `Input your ${displayName}`}>
            {displayName} ({isRequired ? ' Required ' : ' Optional '})
          </span>
        </div>
      </Tooltip>
      <div className="input-wrapper">
        {!this.props.data.is_active
        ? <input className={`field-textbox field-textbox-${fieldName} system-api-key`}
            value={(this.props.data[fieldName] || '')}
            onChange={(e) => this.props.propertiesChanged({[fieldName]: e.target.value})}
            type={fieldName === 'password' ? 'password' : 'text'}
            placeholder={`Enter your ${displayName}`}
          />
        : <div className={`field-textbox field-textbox-${fieldName} system-api-key`}>
            { fieldName === 'password' ? '********' : (this.props.data[fieldName] || '')}
          </div>
        }
      </div>
    </div>
  }
}

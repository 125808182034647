import { Tooltip } from '@progress/kendo-react-tooltip'
import { MultiSelectDropdown } from 'components/dropdown/component'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import React from 'react'
import { Label } from 'reactstrap'
import * as Flash from '../../../flash';
import * as Constants from '../../constants'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

declare const RASA_SELF_SERVICE_API: string

export class ActiveCampaignConfigRender extends IntegrationConfigRenderBase {
  protected showContactsCount = false
  private systemApiKeyName: string = 'systemApiKey'
  private systemAccountName: string = 'systemAccountName'
  private systemEventKey: string = 'systemEventKey'
  private systemActId: string = 'systemActId'
  private eventsToPush: string = 'eventsToPush'

  protected initializeCustomProps() {
    if (this.systemApiAttribute) {
      if (this.systemApiAttribute.access_token) {
        this.props.propertiesChanged({
          [this.systemApiKeyName]: this.systemApiAttribute.access_token,
        })
      }
      if (this.systemApiAttribute.account_name) {
        this.props.propertiesChanged({
          [this.systemAccountName]: this.systemApiAttribute.account_name,
        })
      }
      if (this.systemApiAttribute.event_key) {
        this.props.propertiesChanged({
          [this.systemEventKey]: this.systemApiAttribute.event_key,
        })
      }
      if (this.systemApiAttribute.actid) {
        this.props.propertiesChanged({
          [this.systemActId]: this.systemApiAttribute.actid,
        })
      }
      if (this.systemApiAttribute.events_to_push) {
        this.props.propertiesChanged({
          [this.eventsToPush]: this.systemApiAttribute.events_to_push,
        })
      }
    }
  }

  protected isAuthenticateButtonEnabled(): boolean {
    return !this.state.isConnecting && !!this.props.data.systemApiKey && !!this.props.data.systemAccountName
  }

  protected connect() {
    const url = this.buildPopUpUrl()
    this.setState({
      isConnecting: true,
    })
    AjaxWrapper.ajax(url, HttpMethod.GET, {})
    .then((result) => {
      if (!!result.success) {
        window.location.href = Constants.INTEGRATIONS_CONFIG_URL + '?id=' + result.payload.communitySystemId + '&operation=' + `${result.operation}${Constants.SUCCESS}`
      } else {
        this.setState({
          isConnecting: false,
        })
        const errMsg = result.payload ? result.payload.message : ''
        this.props.context.store.dispatch(Flash.showFlashError(errMsg))
      }
    }).catch((err) => {
      this.setState({
        isConnecting: false,
      })
      let message = ''
      try {
        message = err.response.message
      } catch (e) {
        message = err.message
      }
      this.props.context.store.dispatch(Flash.showFlashError(message))
    })
  }

  protected buildPopUpUrl(): string {
    return `${RASA_SELF_SERVICE_API}/integrations/${this.props.data.systemName.replace(' ', '').toLowerCase()}-api-callback?state=${this.encodedCommunityIdentifier}&api_key=${this.props.data.systemApiKey}&account_name=${this.props.data.systemAccountName}&event_key=${this.props.data[this.systemEventKey]}&actid=${this.props.data[this.systemActId]}&events_to_push=${this.props.data[this.eventsToPush]}`
  }

  protected isTwoWayChecked() {
    return this.props.data && this.props.data.system_api_attribute
      ? JSON.parse(this.props.data.system_api_attribute).sync_direction === 'both' ? true : false
      : true
  }

  protected renderCustomBox() {
    return <div className="api-key-wrapper">
      <div>
        {this.createTextBox('Account Name', this.systemAccountName, true, 'You can find this information on Account Settings page in Active Campaign')}
      </div>
      <div>
        {this.createTextBox('Api Key', this.systemApiKeyName, true)}
      </div>
      <div>
        {this.createTextBox('Event Key', this.systemEventKey, false, 'Can be found on your ActiveCampaign account -> Settings > Tracking > Event Tracking.')}
      </div>
      <div>
        {this.createTextBox('actid', this.systemActId, false, 'Can be found on your ActiveCampaign account -> Settings > Tracking > Event Tracking API -> actid.')}
      </div>
      { this.state.authenticated && this.renderEventIntegration()}
    </div>
  }

  private shouldDisableEventsMultiSelect() {
    return this.props.data.is_active || !this.props.data[this.systemEventKey] || !this.props.data[this.systemActId]
  }
  private renderEventIntegration() {
    return <div className={`events-multi-select-container field-label-credentials ${this.shouldDisableEventsMultiSelect() ? 'disabled' : ''}`}>
      <Label>Select rasa.io events to push into ActiveCampaign:</Label>
      <MultiSelectDropdown
        className={`events-multi-select ${this.shouldDisableEventsMultiSelect() ? 'disabled' : ''}`}
        defaultValue={this.eventsMultiDropdownDefaultValue()}
        options={Constants.RasaEventOptions}
        onSelect={(e) => this.onMultiSelectEventsDropdownChange(e)}
      />
    </div>
  }

  private eventsMultiDropdownDefaultValue() {
    const eventsToPushFromDB = (this.props.data[this.eventsToPush] || '').split(',')
    const result = []
    eventsToPushFromDB.forEach((x) => {
      if (x) {
        result.push(Constants.RasaEventOptions.find((y) => y.value === x ))
      }
    })
    return result
  }

  private onMultiSelectEventsDropdownChange(selections: Constants.RasaEvent[]) {
    const eventsToPush = selections.map((s) => s.value).join(',')
    const newSystemApiAttribute = {
      ...JSON.parse(this.props.data.system_api_attribute),
      events_to_push: eventsToPush,
    }
    this.props.propertiesChanged({
      [this.eventsToPush]: eventsToPush,
      system_api_attribute: JSON.stringify(newSystemApiAttribute),
    })
  }

  private createTextBox(displayName: string, fieldName: string, isRequired: boolean, helpText: string = '') {
    return <div>
      <Tooltip openDelay={100} position="top" anchorElement="pointer" className="rasa-grid-tooltip">
        <div className="field-label-credentials">
          <span title={helpText || `Input your ${displayName}`}>
            {displayName} ({isRequired ? ' Required ' : ' Optional '})
          </span>
        </div>
      </Tooltip>
      <div className="input-wrapper">
        {!this.props.data.is_active
        ? <input className={`field-textbox field-textbox-${fieldName} system-api-key`}
            value={(this.props.data[fieldName] || '')}
            onChange={(e) => this.props.propertiesChanged({[fieldName]: e.target.value})}
            placeholder={`Enter your ${displayName}`}
          />
        : <div className={`field-textbox field-textbox-${fieldName} system-api-key`}>
            {(this.props.data[fieldName] || '')}
          </div>
        }
      </div>
    </div>
  }
}

import { IntegrationSystems } from 'shared/data-layer/integration';

export const UNRESTRICTED_INTEGRATIONS = [
    IntegrationSystems.LinkedIn.toString(),
    IntegrationSystems.PocketRss.toString(),
]

export const isUnrestrictedIntegration = (integrationSystemsName: string) => {
     return UNRESTRICTED_INTEGRATIONS.indexOf(integrationSystemsName) !== -1
}

import { Tooltip } from '@progress/kendo-react-tooltip'
import React from 'react'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

declare const RASA_SELF_SERVICE_API: string

export class ShopifyConfigRender extends IntegrationConfigRenderBase {
  protected initializeCustomProps() {
    if (this.systemApiAttribute && this.systemApiAttribute.shop) {
      this.props.propertiesChanged({
        storeName: this.systemApiAttribute.shop,
      })
    }
  }

  protected buildPopUpUrl(): string {
    const systemName = this.props.data.systemName
    return `https://${this.props.data.storeName.split('.')[0]}${this.systemInfo.keyUrl}${this.encodedCommunityIdentifier}&client_id=${this.props.clientIds[systemName]}&redirect_uri=${RASA_SELF_SERVICE_API}/integrations/${systemName.toLowerCase()}-oauth-callback`
  }

  protected isAuthenticateButtonEnabled(): boolean {
    return !this.state.isConnecting && !!this.props.data.storeName
  }

  protected renderCustomBox() {
    return <div className="store-name-wrapper">
      {this.createTextBox('Store Name', 'storeName')}
    </div>
  }

  private createTextBox(displayName: string, fieldName: string) {
    return <div>
      <Tooltip openDelay={100} position="top" anchorElement="pointer" className="rasa-grid-tooltip">
        <div className="field-label-credentials">
          <span title="Input the name of your shopify store">
            {displayName}
          </span>
        </div>
      </Tooltip>
      <div className="input-wrapper">
        {!this.props.data.id
        ? <input className={`field-textbox field-textbox-${fieldName} field-textbox-credentials`}
            value={(this.props.data[fieldName] || '').split('.')[0]}
            onChange={(e) => this.props.propertiesChanged({[fieldName]: e.target.value})}
            placeholder="Enter your Shopify store name"
          />
        : <div className={`field-textbox field-textbox-${fieldName} field-textbox-credentials`}>
            {(this.props.data[fieldName] || '').split('.')[0]}
          </div>
        }
        <div className="shopname-ending">
          <p>.myshopify.com</p>
        </div>
      </div>
    </div>
  }
}

import { Tooltip } from '@progress/kendo-react-tooltip'
import classnames from 'classnames'
import { MultiSelectDropdown } from 'components/dropdown/component'
import { validateForgivingUrl } from 'components/validation/validations'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import React from 'react'
import * as Flash from '../../../flash';
import * as Constants from '../../constants'
import * as Integrations from '../integrations'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

declare const RASA_SELF_SERVICE_API: string

export class WordPressConfigRender extends IntegrationConfigRenderBase {
  protected showContactsCount = false
  private systemSiteKeyName: string = 'api_endpoint'
  private usernameKeyName: string = 'username'
  private passwordKeyName: string = 'password'

  protected initializeCustomProps() {
    if (this.systemApiAttribute) {
      if (this.systemApiAttribute[this.systemSiteKeyName]) {
       this.props.propertiesChanged({
         [this.systemSiteKeyName]: this.systemApiAttribute[this.systemSiteKeyName],
       })
      }
      if (this.systemApiAttribute[this.usernameKeyName]) {
        this.props.propertiesChanged({
          [this.usernameKeyName]: this.systemApiAttribute[this.usernameKeyName],
        })
      }
      if (this.systemApiAttribute[this.passwordKeyName]) {
        this.props.propertiesChanged({
          [this.passwordKeyName]: this.systemApiAttribute[this.passwordKeyName],
        })
      }
    }
  }

  protected isAuthenticateButtonEnabled(): boolean {
    return !this.state.isConnecting &&
            !!this.props.data[this.systemSiteKeyName] &&
            !!this.props.data[this.usernameKeyName] &&
            !!this.props.data[this.passwordKeyName] &&
            validateForgivingUrl(this.props.data[this.systemSiteKeyName]).valid
  }

  protected connect() {
    const url = this.buildPopUpUrl()
    this.setState({
      isConnecting: true,
    })
    AjaxWrapper.ajax(url, HttpMethod.GET, {})
    .then((result) => {
      this.setState({
        isConnecting: false,
      })
      if (result.success) {
        window.location.href = Constants.INTEGRATIONS_CONFIG_URL + '?id=' + result.payload.communitySystemId + '&operation=' + `${result.operation}${Constants.SUCCESS}`
      } else {
        this.setState({
          addFailure: true,
          message: result.statusCode,
        })
      }
    }).catch((err) => {
      this.setState({
        isConnecting: false,
      })
      let message = ''
      try {
        message = err.response.message
      } catch (e) {
        message = err.message
      }
      this.props.context.store.dispatch(Flash.showFlashError(message))
    })
  }

  protected buildPopUpUrl(): string {
    const { data } = this.props
    const origin = new URL(data[this.systemSiteKeyName]).origin
    return `${RASA_SELF_SERVICE_API}/integrations/${this.communityId}/${data.systemName}/api-callback?api_endpoint=${origin}&username=${data[this.usernameKeyName]}&password=${btoa(data[this.passwordKeyName])}`
  }

  protected renderCustomBox() {
    return <div>
            <div className="credentials-wrapper">
              {this.createTextBox('Website URL', this.systemSiteKeyName)}
            </div>
            <div className="credentials-wrapper">
              {this.createTextBox('Username', this.usernameKeyName)}
              {this.createTextBox('Application Password', this.passwordKeyName)}
            </div>
          </div>
  }

  protected renderFilter() {
    if (this.supportsFilter) {
      if (this.state.filters || this.props.data.selectedFilterName) {
        return <div className="filter-wrapper">
          <div className="filter-name-wrapper">
            <div>
              {this.state.dropdownAvailable && this.state.authenticated &&
                <div className="field-label-credentials">
                  {Integrations.systemInformation(this.props.data.systemName).syncText}
                </div>
              }
              <div className="filter-name-box">
                <div className="filter-name-text">
                  {(this.state.filters && this.state.dropdownAvailable)
                    ? <div className="filters-dropdown">
                       <MultiSelectDropdown
                          options={this.state.filters}
                          onSelect={(e) => this.onMultiSelectDropdownChange(e)}
                        />
                      </div>
                    : <div>
                      {this.props.data.is_active && this.props.data.selectedFilterName
                        ? <div>
                          {`You have successfully connected
                          ${this.props.data.selectedFilterName !== Constants.ALL_ROLES
                          ? ` ${this.formatedFilterDescription()}:`
                          : ':'}
                          `}
                          <div className="selected-filter-name-wrapper"> {this.props.data.selectedFilterName}</div>
                        </div>
                        : null
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    }
  }

  private createTextBox(displayName: string, fieldName: string) {
    return <div style={{width: fieldName === this.systemSiteKeyName ? '100%' : '50%'}}>
      <Tooltip openDelay={100} position="top" anchorElement="pointer" className="rasa-grid-tooltip">
        <div className="field-label-credentials">
          <span title={`Input your ${displayName}`}>
            {displayName}
          </span>
        </div>
      </Tooltip>
      <div className={classnames(displayName.toLocaleLowerCase(), 'input-wrapper')}>
        {!this.props.data.is_active && !this.state.authenticated
        ? <input className={`field-textbox field-textbox-${fieldName} system-api-key`}
            value={(this.props.data[fieldName] || '')}
            onChange={(e) => this.props.propertiesChanged({[fieldName]: e.target.value})}
            placeholder={`Enter your ${displayName} ${fieldName === this.systemSiteKeyName ? 'starting with https://' : ''}`}
            type={displayName.includes('Password') ? 'password' : 'text'}
            required
          />
        : <div className={`field-textbox field-textbox-${fieldName} system-api-key`}>
            {(this.safeDisplay(fieldName) || '')}
          </div>
        }
      </div>
    </div>
  }

  private safeDisplay(fieldName) {
    return fieldName === this.passwordKeyName
    ? '************'
    : this.props.data[fieldName]
  }
}

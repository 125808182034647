import React from 'react'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'

declare const RASA_SELF_SERVICE_API: string

export class PocketRssConfigRender extends IntegrationConfigRenderBase {
  protected supportsFilter: boolean = false

  protected initializeCustomState(): Promise<void> {
    if (this.props.params.operation) {
      if (!this.props.params.operation.includes('Failure')) {
        this.props.push(DashboardMenuOption.sources)
      }
    }
    return Promise.resolve()
  }

  protected connect() {
    const url = `${AjaxWrapper.getServerUrl()}/integrations/pocketrss-generate-oauth-code`
    AjaxWrapper.ajax(url, HttpMethod.GET, {})
    .then((response) => {
      const decodedState = `${this.communityId}|${response.code}`
      const endcodedState = btoa(decodedState)
      const redirect_url = `${AjaxWrapper.getServerUrl()}/integrations/pocketrss-oauth-callback?state=${endcodedState}`
      const authUrl = `${this.systemInfo.keyUrl}?request_token=${response.code}&redirect_uri=${redirect_url}`
      this.openSignInPopup(authUrl)
    })
  }

  protected renderDisconnect() {
    if (!!this.props.data.is_active) {
      return <div className="connect-disconnect-wrapper">
        <div className={'save-button clickable-item save-button-enabled disconnect'}
          onClick={(e) => this.disconnect()}>Disconnect
        </div>
      </div>
    }
  }
}

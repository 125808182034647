import { OnChangeEvent } from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { Loading, SIZES } from 'components/loading'
import {RasaTimeZone} from 'components/time-zone/component'
import { RasaContext } from 'context'
import { BaseClientEntity, RecordIdType } from 'generic/baseClientEntity'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isEmpty, isNil } from 'lodash'
import React from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import '../styles.css'
import { TextBox } from '../textbox'
import * as Types from '../types'

type CompanySettingsProps = GenericRedux.AllComponentProps<Types.FullProfile>

interface CompanySettingsState {
  activeCommunity: any,
  changes: any,
  isProfileLoading: boolean,
  person: any,
}

class CompanySettingsComponent extends RasaReactComponent<CompanySettingsProps, CompanySettingsState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: CompanySettingsProps) {
    super(props, 'userAndAccount', {
      activeCommunity: {},
      changes: {},
      isProfileLoading: true,
      person: {},
    })
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
    ]).then(([activeCommunity, person]) => {
      this.setState({
        activeCommunity,
        communityId: activeCommunity.communityId,
        person,
      })
      return this.loadRecord(activeCommunity.communityId, activeCommunity.communityAccountId).then(() => {
        return this.setState({isProfileLoading: false})
      })
    })
  }

  public saveRecord(recordId: RecordIdType): Promise<any> {
    return super.saveRecord(recordId)
                .then((result: any) => {
                  return this.sharedStore.refresh().then(() => {
                    this.setState({
                      changes: {},
                    })
                    return result
                  })
                })
                .catch((err) => {
                  // eslint-disable-next-line no-console
                  console.log('Error: ', err)
                  this.setState({ isSaving: false })
                })
  }

  public loadRecord(communityId: string, recordId: RecordIdType): Promise<boolean> {
    this.setState({
      isDirty: false,
      changes: {},
    })
    const accountData = this.state.person.accountInfo
    let communityData: any = {}
    if (this.state.activeCommunity.communityInfo.data.community.length) {
      communityData = this.state.activeCommunity.communityInfo.data.community[0]
      if (isEmpty(communityData.display_name)) {
        communityData.display_name = communityData.name;
        //Maintain and Save the 'display_name' with initial value of 'Company Name' value
        //if Company name changed after page load(changed manually)
        this.setState({
          changes: {
            ...this.state.changes,
            ['display_name']: communityData.name,
          },
        })
      }
    }

    this.updateComponentState({
      address_1: communityData.address_1,
      city: communityData.city,
      company_name: accountData.company_name,
      community_name: communityData.name,
      country: communityData.country,
      display_name: communityData.display_name,
      phone: communityData.phone,
      post_code: communityData.post_code,
      state: communityData.state,
      timezone: communityData.timezone,
    })
    return Promise.resolve(true)
  }

  public mapPropsToEntityObject(entityObject: BaseClientEntity): void {
    Object.keys(this.state.changes).forEach((key: string) => {
      entityObject.data[key] = this.state.changes[key]
    })
  }

  public propertyChanged(field: string, value: string) {
    super.propertyChanged(field, value)
    this.setState({
      changes: {
        ...this.state.changes,
        [field]: value,
      },
    })
  }

  public render() {
    return (
      <div className="settings-wrapper">
        {this.state.isProfileLoading ? <Loading size="64"/> :
        <div className="menuline1">
          <div className="header-container">
            <HeaderComponent
              title={'Settings'}
              subTitle={'Company'}
              description={['This information is used in your newsletter footers for CAN-SPAM compliance.']}
            />
            <div className={`save-button ${this.isDirty && !this.isSaving ? 'clickable-item save-button-enabled' : 'save-button-disabled'}`}
                  onClick={(e) => this.isDirty && this.saveRecord(this.state.communityId)}>
              {this.isSaving ? <Loading size={SIZES.MEDIUM}/> : 'Update Settings'}
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="field-left">
            {this.createTextBox('COMPANY NAME', 'community_name')}
          </div>
          <div className="field-right">
            {this.createTextBox('PHONE NUMBER', 'phone')}
          </div>
          <div className="clearfix"></div>
          <div className="field-left">
            {this.createTextBox('COMPANY ADDRESS', 'address_1')}
          </div>
          <div className="field-right">
            {this.createTextBox('CITY', 'city')}
          </div>
          <div className="clearfix"></div>
          <div className="field-left">
            {this.createTextBox('STATE', 'state')}
          </div>
          <div className="field-right">
            {this.createTextBox('COUNTRY', 'country')}
          </div>
          <div className="field-right">
            {this.createTextBox('ZIP CODE', 'post_code')}
          </div>
          <div className="field-right">
            <div className="field-label">Timezone</div>
            <RasaTimeZone timeZone={this.props.data.timezone} onChange={this.timeZoneChanged} />
          </div>
          <div className="borderline1"></div>
          <div className="borderline2"></div>
          <div className="clearfix"></div>
          <div className="field-left">
            {this.createTextBox('NEWSLETTER NAME', 'display_name')}
          </div>
          <div className="borderline2"></div>
          <div className="clearfix "></div>
        </div>
        }
      </div>
    )
  }

  private createTextBox(displayName: string, fieldName: string) {
    return <TextBox name={displayName} field={fieldName}
                    value={this.props.data[fieldName]}
                    dirty={!isNil(this.state.changes[fieldName])}
                    onChange={(field: string, value: string) => this.propertyChanged(field, value)}/>
  }

  private timeZoneChanged = (e: OnChangeEvent) => {
    this.propertyChanged('timezone', e.selected.key.toString())
  }
}

export const CompanySettings = GenericRedux.registerNewNestedComponent<Types.FullProfile>(
  CompanySettingsComponent, 'company_settings')

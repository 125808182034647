import React from 'react'
import { Col } from 'reactstrap'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

export class ReplugConfigRender extends IntegrationConfigRenderBase {

  protected showSyncNow = false

  protected renderFinalizeHtml() {
    return <div>
      <Col className="confirm-connect">
        <p className="text">You are about to connect your Campaign "{this.props.data.selectedFilterName}".
          &nbsp;Is that what you meant to do?
        </p>
        <p className="confirm">
          <input className="checkbox" type="checkbox"
            checked={this.state.confirmed}
            onChange={() => this.setState({ confirmed: !this.state.confirmed })}
            id="confirm-checkbox" />
          <label htmlFor="confirm-checkbox">Yes</label>
        </p>
      </Col>
      <div className={this.state.confirmed ? 'save-button clickable-item save-button-enabled authenticate' :
        'save-button clickable-item save-button-disabled authenticate not-allowed'}
        onClick={() => this.finalizeSetup()}> Finalize connection
      </div>
    </div>
  }
}

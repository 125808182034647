import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

declare const RASA_SELF_SERVICE_API: string

export class MailchimpConfigRender extends IntegrationConfigRenderBase {

  protected buildPopUpUrl(): string {
    let rasaSelfServiceApi = RASA_SELF_SERVICE_API
    if (rasaSelfServiceApi.includes('localhost')) {
      rasaSelfServiceApi = rasaSelfServiceApi.replace('localhost', '127.0.0.1')
    }
    return `${this.systemInfo.keyUrl}${this.encodedCommunityIdentifier}&client_id=${this.props.clientIds[this.props.data.systemName]}&redirect_uri=${rasaSelfServiceApi}/integrations/${this.props.data.systemName.toLowerCase()}-oauth-callback`
  }
}

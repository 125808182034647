import { Tooltip } from '@progress/kendo-react-tooltip'
import classnames from 'classnames'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import React from 'react'
import * as Flash from '../../../flash';
import * as Constants from '../../constants'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

declare const RASA_SELF_SERVICE_API: string

export class HigherLogicMagnetMailConfigRender extends IntegrationConfigRenderBase {
  protected showContactsCount = false
  private usernameKeyName: string = 'username'
  private passwordKeyName: string = 'password'

  protected initializeCustomProps() {
    if (this.systemApiAttribute) {
      if (this.systemApiAttribute[this.usernameKeyName]) {
        this.props.propertiesChanged({
          [this.usernameKeyName]: this.systemApiAttribute[this.usernameKeyName],
        })
      }
      if (this.systemApiAttribute[this.passwordKeyName]) {
        this.props.propertiesChanged({
          [this.passwordKeyName]: this.systemApiAttribute[this.passwordKeyName],
        })
      }
    }
  }

  protected isAuthenticateButtonEnabled(): boolean {
    return !this.state.isConnecting &&
            !!this.props.data[this.usernameKeyName] &&
            !!this.props.data[this.passwordKeyName]
  }

  protected connect() {
    const url = this.buildPopUpUrl()
    this.setState({
      isConnecting: true,
    })
    AjaxWrapper.ajax(url, HttpMethod.GET, {})
    .then((result) => {
      if (!!result.success) {
        window.location.href = Constants.INTEGRATIONS_CONFIG_URL + '?id=' + result.payload.communitySystemId + '&operation=' + `${result.operation}${Constants.SUCCESS}`
      } else {
        let operation = Constants.UPDATE
        let systemParamName = 'id'
        let systemParamValue: string = this.props.data.id ? this.props.data.id.toString() : ''
        if (!this.props.data.id) {
          systemParamName = 'systemName'
          systemParamValue = this.props.data.systemName
          operation = Constants.CREATE
        }
        window.location.href = `${Constants.INTEGRATIONS_CONFIG_URL}?${systemParamName}=${systemParamValue}&operation=${operation}${Constants.FAILURE}`
      }
    }).catch((err) => {
      this.setState({
        isConnecting: false,
      })
      let message = ''
      try {
        message = err.response.message
      } catch (e) {
        message = err.message
      }
      this.props.context.store.dispatch(Flash.showFlashError(message))
    })
  }

  protected buildPopUpUrl(): string {
    return `${RASA_SELF_SERVICE_API}/integrations/${this.props.data.systemName.toLowerCase()}-api-callback?state=${this.encodedCommunityIdentifier}&${this.usernameKeyName}=${this.props.data[this.usernameKeyName]}&${this.passwordKeyName}=${btoa(this.props.data[this.passwordKeyName])}`
  }

  protected renderCustomBox() {
    return <div>
              <div className="credentials-wrapper">
                {this.createTextBox('Username', this.usernameKeyName)}
                {this.createTextBox('Password', this.passwordKeyName)}
              </div>
          </div>
  }

  private createTextBox(displayName: string, fieldName: string) {
    return <div style={{width: '100%'}}>
      <Tooltip openDelay={100} position="top" anchorElement="pointer" className="rasa-grid-tooltip">
        <div className="field-label-credentials">
          <span title={`Input your ${displayName}`}>
            {displayName}
          </span>
        </div>
      </Tooltip>
      <div className={classnames(displayName.toLocaleLowerCase(), 'input-wrapper')}>
        {!this.props.data.is_active
        ? <input className={`field-textbox field-textbox-${fieldName} system-api-key`}
            value={(this.props.data[fieldName] || '')}
            onChange={(e) => this.props.propertiesChanged({[fieldName]: e.target.value})}
            placeholder={`Enter your ${displayName}`}
            type={displayName === 'Password' ? 'password' : 'text'}
            required
          />
        : <div className={`field-textbox field-textbox-${fieldName} system-api-key`}>
            {(this.safeDisplay(fieldName) || '')}
          </div>
        }
      </div>
    </div>
  }

  private safeDisplay(fieldName) {
    return fieldName === this.passwordKeyName
    ? '************'
    : this.props.data[fieldName]
  }
}

import { MultiSelectDropdown } from 'components/dropdown/component'
import React from 'react'
import * as Constants from '../../constants'
import * as Integrations from '../integrations'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

export class InfusionsoftConfigRender extends IntegrationConfigRenderBase {
  protected showContactsCount = false

  protected renderFilter() {
    if (this.supportsFilter) {
      if (this.state.filters || this.props.data.selectedFilterName) {
        return <div className="filter-wrapper">
          <div className="filter-name-wrapper">
            <div>
              {this.state.dropdownAvailable && this.state.authenticated &&
                <div className="field-label-credentials">
                  {Integrations.systemInformation(this.props.data.systemName).syncText}
                </div>
              }
              <div className="filter-name-box">
                <div className="filter-name-text">
                  {(this.state.filters && this.state.dropdownAvailable)
                    ? <div className="filters-dropdown">
                       <MultiSelectDropdown
                          options={this.state.filters}
                          onSelect={(e) => this.onMultiSelectDropdownChange(e)}
                        />
                      </div>
                    : <div>
                      {this.props.data.is_active && this.props.data.selectedFilterName
                        ? <div>
                          {`You have successfully connected
                          ${this.props.data.selectedFilterName !== Constants.ALL_ROLES
                          ? ` ${this.formatedFilterDescription()}:`
                          : ':'}
                          `}
                          <div className="selected-filter-name-wrapper"> {this.props.data.selectedFilterName}</div>
                        </div>
                        : null
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    }
  }

  protected formatedFilterDescription() {
    return this.filter.description
  }

}

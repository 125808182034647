import { Tooltip } from '@progress/kendo-react-tooltip'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import React from 'react'
import * as Flash from '../../../flash';
import * as Constants from '../../constants'
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase'

declare const RASA_SELF_SERVICE_API: string

export class SalesForceCloudRender extends IntegrationConfigRenderBase {
	protected supportsFilter: boolean = false
	protected showContactsCount = false
	private clientId: string = 'clientId'
	private clientSecret: string ='clientSecret'
	private accountId: string ='accountId'
	private subDomain: string= 'subDomain'
	private dataExtensionKey: string = 'dataExtensionKey'
	protected initializeCustomProps() {
		if (!this.systemApiAttribute) {
			return
		}
		if (this.systemApiAttribute.client_id) {
			this.props.propertiesChanged({[this.clientId]: this.systemApiAttribute.client_id})
		}
		if (this.systemApiAttribute.client_secret) {
			this.props.propertiesChanged({[this.clientSecret]: this.systemApiAttribute.client_secret})
		}
		if (this.systemApiAttribute.account_id) {
			this.props.propertiesChanged({[this.accountId]: this.systemApiAttribute.account_id})
		}
		if (this.systemApiAttribute.sub_domain) {
			this.props.propertiesChanged({[this.subDomain]: this.systemApiAttribute.sub_domain})
		}
		if (this.systemApiAttribute.data_extension_key) {
			this.props.propertiesChanged({[this.dataExtensionKey]: this.systemApiAttribute.data_extension_key})
		}
	}

	protected isAuthenticateButtonEnabled(): boolean {
		return !this.state.isConnecting && !!this.props.data.clientId && !!this.props.data.clientSecret
			&& !!this.props.data.accountId && !!this.props.data.subDomain && !!this.props.data.dataExtensionKey
	}

	protected connect() {
		const url = this.buildPopUpUrl()
		this.setState({
			isConnecting: true,
		})
		AjaxWrapper.ajax(url, HttpMethod.GET, {})
		.then((result) => {
			if (!!result.success) {
				window.location.href = Constants.INTEGRATIONS_CONFIG_URL + '?id=' + result.payload.communitySystemId + '&operation=' + `${result.operation}${Constants.SUCCESS}`
			} else {
				this.setState({
					isConnecting: false,
				})
				const errMsg = result.payload ? result.payload.message : ''
				this.props.context.store.dispatch(Flash.showFlashError(errMsg))
			}
		})
		.catch((err) => {
			this.setState({isConnecting: false})
			let message = ''
			try {
				message = err.response.message
			} catch (e) {
				message = err.message
			}
			this.props.context.store.dispatch(Flash.showFlashError(message))
		})
	}

	protected buildPopUpUrl(): string {
		return `${RASA_SELF_SERVICE_API}/integrations/${this.props.data.systemName.replace(' ', '').toLowerCase()}-api-callback?state=${this.encodedCommunityIdentifier}&client_id=${this.props.data.clientId}&client_secret=${this.props.data.clientSecret}&account_id=${this.props.data.accountId}&sub_domain=${this.props.data.subDomain}&data_extension_key=${this.props.data.dataExtensionKey}`
	}

	protected renderCustomBox() {
		return <div className="api-key-wrapper">
			<div>
				{this.createTextBox('Client Id', this.clientId)}
			</div>
			<div>
				{this.createTextBox('Client Secret', this.clientSecret)}
			</div>
			<div>
				{this.createTextBox('Account Id', this.accountId)}
			</div>
			<div>
				{this.createTextBox('Sub Domain', this.subDomain)}
			</div>
			<div>
				{this.createTextBox('Data Extension Key / Audience Key', this.dataExtensionKey)}
			</div>
		</div>
	}

	private createTextBox(displayName: string, fieldName: string, helpText: string = '', isRequired = true) {
		return <div>
			<Tooltip openDelay={100} position="top" anchorElement="pointer" className="rasa-grid-tooltip">
				<div className="field-label-credentials">
					<span title={helpText || `Input your ${displayName}`}>
						{displayName} {isRequired ? '(Required)' : ''}
					</span>
				</div>
			</Tooltip>
			<div className="input-wrapper">
				{!this.props.data.is_active
				? <input
						className={`field-textbox field-textbox-${fieldName} system-api-key`}
						value={(this.props.data[fieldName] || '')}
						onChange={(e) => this.props.propertiesChanged({[fieldName]: e.target.value})}
						placeholder={`Enter your ${displayName}`}
					/>
				: <div className={`field-textbox field-textbox-${fieldName} system-api-key`}>
						{(this.props.data[fieldName] || '')}
					</div>
				}
			</div>
		</div>
	}
}

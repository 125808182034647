import * as Flash from 'components/flash'
import { EmailSent } from 'components/icons/emailSent'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isTruthy } from 'generic/utility'
import React from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { EmailPreference, EmailPreferences, getAttribute,
  PREFERENCE_UPDATE_FAILURE, PREFERENCE_UPDATE_SUCCESS } from '../constants'
import '../styles.css'
import { EmailPreferenceItem } from './emailPreferenceItem'
import { CommunityDropdown } from 'components/community-dropdown/component'

type SettingsEmailPreferenceProps = GenericRedux.AllComponentProps<EmailPreference>

interface SettingsEmailPreferenceState {
  communityId: number,
  userCommunityId: number
}
const emptyState: SettingsEmailPreferenceState = {
  communityId: null,
  userCommunityId: null,
}

class SettingsEmailPreferenceComponent extends RasaReactComponent<
  SettingsEmailPreferenceProps, SettingsEmailPreferenceState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: SettingsEmailPreferenceProps) {
    super(props, 'userCommunityPreference', emptyState)
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
    .then((activeCommunity) => {
      this.setState({
        communityId: activeCommunity.communityId,
        userCommunityId: activeCommunity.data.community_user_id,
      })
      this.loadRecord(activeCommunity.communityId, activeCommunity.data.community_user_id)
    })
  }

  public render() {
    return (
      <div className="settings-email-preference-wrapper">
        <div>
          <div className="contentpane-header-wrapper">
            <div className="contentpane-icon-wrapper">
              <EmailSent/>
            </div>
            <div className="header">
              <div className="header-outter-wrapper">
                <div className="contentpane-section-title-wrapper">
                  <h6 className="section-header-title">SETTINGS</h6>
                  <div className="section-header-text">Email Preferences</div>
                </div>
              </div>
            </div>
          </div>
          <div className="email-preferences-community-list">
            <CommunityDropdown showOnlyWhenMultiple={true} showLabel={true} onCommunityChange={this.onCommunityChange} />
          </div>
          <div className="email-preferences">
              {EmailPreferences.map((preference: EmailPreference) =>
                <EmailPreferenceItem key={preference.name} preference={preference}
                  isActive={getAttribute(this.props.data, preference.name)}
                  onToggle={() => this.onToggle(preference) } />)}
            </div>
        </div>
      </div>
    )
  }

  private onCommunityChange = (community) => {
    this.setState({communityId: community.community_identifier})
    this.loadRecord(community.community_identifier, community.community_user_id)
  }

  private onToggle = (preference: EmailPreference) => {
    const newActive = !isTruthy(getAttribute(this.props.data, preference.name))
    this.props.propertiesChanged({[preference.name]: newActive})
    this.context.entityMetadata.getEntityObject(this.entityName)
      .then((entity) => {
        entity.setRecordId(this.state.communityId, null)
        entity.data.preference_type = preference.name
        entity.data.user_community_id = this.state.userCommunityId
        entity.data.is_active = newActive
        return entity.save().then(() =>
          this.context.store.dispatch(Flash.showFlashMessage(PREFERENCE_UPDATE_SUCCESS)),
        )
        .catch(() => {
          this.context.store.dispatch(Flash.showFlashError(PREFERENCE_UPDATE_FAILURE))
        })
      });
  }
}

export const SettingsEmailPreferences = GenericRedux.registerNewNestedComponent(
  SettingsEmailPreferenceComponent, 'settings_email_preferences')
